/* src/theme/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* eslint-disable */
.DokaButton {
  white-space: nowrap;
  flex-shrink: 0;
  -webkit-tap-highlight-color: transparent;
  cursor: var(--button-cursor);
}
.DokaButton > span {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
}
.DokaButton svg + .DokaButtonLabel {
  margin-left: 0.5em;
}
.DokaButton[disabled] {
  cursor: default;
}
.DokaCanvas canvas {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.DokaColorPickerPanel {
  padding: 0.5em;
  max-width: 14em;
  overflow: visible;
  touch-action: none;
}
.DokaColorPickerPanel .DokaColorPickerButton {
  outline: transparent;
}
.DokaColorPickerPanel .DokaColorPickerButton[data-focus-visible] {
  box-shadow: inset 0 0 0 1px var(--color-foreground-70);
}
.DokaColorPickerPanel .DokaColorPreview {
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.25em;
}
.DokaColorPickerPanel .DokaColorPreview span {
  border-radius: 0.2em;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
}
.DokaColorPickerPanel .DokaColorPresets legend {
  color: #777;
  margin: 0 0 0.25em 0.3125em;
  font-size: 0.75em;
}
.DokaColorPickerPanel .DokaColorPresetsGrid .DokaRadioGroupOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.DokaColorPickerPanel .DokaColorPresetsGrid .DokaDropdownOption {
  display: block;
  padding: 0.25em;
}
.DokaColorPickerPanel .DokaColorPresetsGrid .DokaDropdownOption label,
.DokaColorPickerPanel .DokaColorPresetsGrid .DokaDropdownOption label span {
  display: block;
  cursor: var(--button-cursor);
}
.DokaColorPickerPanel
  .DokaColorPresetsGrid
  input[data-focus-visible]
  + label
  .DokaColorPreview:after {
  position: absolute;
  left: -0.3125em;
  right: -0.3125em;
  top: -0.3125em;
  bottom: -0.3125em;
  border-radius: inherit;
  pointer-events: none;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaColorPickerPanel .DokaPicker {
  width: 100%;
  padding-bottom: 0.5em;
}
.DokaColorPickerPanel .DokaPicker .DokaPickerKnob,
.DokaColorPickerPanel .DokaPicker .DokaSliderKnob {
  border-radius: 9999em;
  box-shadow: inset 0 0.06125em 0.06125em rgba(0, 0, 0, 0.1), 0 0 0 0.125em #fff,
    0 0.1875em 0.25em rgba(0, 0, 0, 0.35), 0 -0.125em 0.25em rgba(0, 0, 0, 0.1);
}
.DokaColorPickerPanel .DokaPicker .DokaPickerKnob {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin-left: -0.625em;
  margin-top: -0.625em;
  width: 1.25em;
  height: 1.25em;
  visibility: visible;
  outline: none;
}
.DokaColorPickerPanel .DokaPicker .DokaPickerKnob[data-focus-visible]:after {
  position: absolute;
  left: -0.3125em;
  right: -0.3125em;
  top: -0.3125em;
  bottom: -0.3125em;
  border-radius: inherit;
  pointer-events: none;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaColorPickerPanel .DokaPicker .DokaPickerKnobController {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
}
.DokaColorPickerPanel .DokaPicker .DokaSaturationPicker {
  position: relative;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  margin: -0.5em -0.5em 0.5em;
  height: 7em;
  background-image: linear-gradient(180deg, transparent, #000),
    linear-gradient(90deg, #fff, transparent);
}
.DokaColorPickerPanel .DokaPicker .DokaHuePicker .DokaSliderTrack {
  background-image: linear-gradient(
    90deg,
    red,
    #ff0,
    #0f0,
    #0ff,
    #00f,
    #f0f,
    red
  );
}
.DokaColorPickerPanel .DokaPicker .DokaOpacityPicker .DokaSliderTrack:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: inherit;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h4v4H0zm4 4h4v4H4z' fill='%23E5E5E5'/%3E%3C/svg%3E");
}
.DokaColorPickerPanel .DokaPicker .DokaOpacityPicker {
  margin-top: 0.5em;
}
.DokaColorPreview {
  --color: transparent;
  color: var(--color);
  position: relative;
  pointer-events: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h4v4H0zm4 4h4v4H4z' fill='%23E5E5E5'/%3E%3C/svg%3E");
}
.DokaColorPreview span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color);
  border-radius: inherit;
}
.DokaDetailsPanel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2147483647;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: auto;
  outline: transparent;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25),
    0 0.125em 0.75em rgba(0, 0, 0, 0.15), 0 0.125em 0.125em rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  color: #111;
  background: #fff;
}
.DokaDragButton {
  cursor: var(--button-cursor);
  outline: transparent;
}
.DokaDragButton > * {
  pointer-events: none;
}
.DokaDragButton {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: filter 0.2s ease-out;
}
.DokaDragButton[disabled] {
  filter: var(--filter-disabled);
}
.DokaDropdownPanel .DokaDropdownOption {
  transition: background-color 0.1s;
  border-radius: inherit;
}
.DokaDropdownPanel .DokaDropdownOption label {
  display: block;
  margin: 0;
  border-radius: inherit;
  white-space: nowrap;
}
.DokaDropdownPanel .DokaDropdownOption svg {
  margin-right: 0.25em;
  margin-left: -0.5em;
  min-width: 1.5em;
}
.DokaDropdownPanel .DokaDropdownOption [slot] {
  display: flex;
  align-items: center;
  padding: 0.325em 0.75em;
}
.DokaDropdownPanel .DokaDropdownOptionLabel {
  font-size: 0.75em;
  line-height: 1.75;
  padding: 0 0.325em;
  margin-left: -0.325em;
}
.DokaDropdownPanel .DokaDropdownOptionGroup > span {
  display: block;
  padding: 0.75em 1em;
  text-transform: uppercase;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.75);
}
.DokaDropdownPanel [data-disabled="true"] {
  color: #999;
}
.DokaDropdownPanel [data-selected="false"]:not([data-disabled="true"]):hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.DokaDropdownPanel [data-selected="true"] {
  color: #fff;
  background: #000;
}
.DokaDropdownPanel [data-focus-visible] + label .DokaDropdownOptionLabel {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: 0.25em;
}
.DokaDropdownOption label {
  margin: 0;
}
.DokaInputForm {
  opacity: 0;
  cursor: auto;
  z-index: 2147483647;
}
.DokaInputForm:not([data-layout="stick"]) {
  position: absolute;
  bottom: 6.5em;
  width: 30em;
  left: calc(50% - 15em);
  box-shadow: 0 0.25em 0.5em -0.5em rgba(0, 0, 0, 0.75),
    inset 0 0 0 1px var(--color-foreground-10);
  border-radius: var(--border-radius);
  background-color: var(--color-background-95);
}
@media (max-width: 34em) {
  .DokaInputForm:not([data-layout="stick"]) {
    left: 2em;
    right: 2em;
    width: auto;
  }
}
.DokaInputForm[data-layout="stick"] {
  transition: opacity 0.15s ease 0.25s;
  position: absolute;
  left: 0;
  right: 0;
  outline: 1px solid var(--color-foreground-15);
  background-color: var(--color-background-100);
}
.DokaInputForm[data-layout="stick"] > .DokaInputFormInner {
  background-color: var(--color-foreground-3);
}
.DokaInputFormInner > .DokaButton {
  color: var(--color-foreground-100);
  border-radius: 9999em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaInputFormInner > .DokaButton:first-of-type {
  left: 1em;
}
.DokaInputFormInner > .DokaButton:last-of-type {
  right: 1rem;
  color: var(--color-primary-text);
  background: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}
.DokaInputFormInner > .DokaButton:not(.DokaButtonIconOnly) .DokaButtonInner {
  padding: 0 0.875em 0 0.625em;
}
.DokaInputFormInner > .DokaButton .DokaButtonIcon {
  width: 0.875em;
  height: 0.875em;
}
.DokaInputFormInner > .DokaButton.DokaButtonIconOnly {
  width: 1.875em;
  height: 1.875em;
}
.DokaInputFormInner > .DokaButton .DokaButtonLabel {
  font-size: 0.875em;
}
.DokaInputFormInner > .DokaButton .DokaButtonInner {
  line-height: 2;
  display: flex;
  justify-content: center;
}
.DokaInputFormInner {
  padding-bottom: 3.5em;
}
.DokaInputFormInner > .DokaButton {
  position: absolute;
  bottom: 1em;
}
.DokaInputFormFields textarea:only-child {
  width: 100%;
  margin-top: 1em;
  padding: 0 1em 0.5em;
  height: 5em;
  display: block;
  background: transparent;
  -webkit-mask: linear-gradient(0deg, transparent, #000 1em);
  mask: linear-gradient(0deg, transparent, #000 1em);
}
.DokaInputFormFields textarea:only-child::-webkit-scrollbar {
  width: 1em;
  cursor: pointer;
}
.DokaInputFormFields textarea:only-child::-webkit-scrollbar-track {
  background: none;
}
.DokaInputFormFields textarea:only-child::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-clip: padding-box;
  background-color: var(--color-foreground-70);
  border-radius: 9999em;
  border: 0.25em solid transparent;
}
.DokaInputForm input,
.DokaInputForm textarea {
  box-sizing: border-box;
  resize: none;
  border: none;
  font-size: 1rem;
  outline: transparent;
  color: var(--color-foreground-100);
}
.DokaInputForm input {
  background-color: var(--color-background-100);
}
.DokaPanel {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  contain: layout size;
}
.DokaProgressIndicator {
  position: relative;
  display: block;
  width: 1.25em;
  height: 1.25em;
}
.DokaProgressIndicator .DokaProgressIndicatorFill {
  stroke-dashoffset: 0;
}
.DokaProgressIndicator[data-status="busy"] svg {
  -webkit-animation: doka_spin 1s linear infinite;
  animation: doka_spin 1s linear infinite;
}
.DokaProgressIndicator[data-status="busy"] .DokaProgressIndicatorFill {
  stroke-dashoffset: -26.5;
  stroke-dasharray: 26.5 53;
  transition: stroke-dashoffset 0.5s linear, stroke-dasharray 0.5s linear;
}
@-webkit-keyframes doka_spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes doka_spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .DokaRoot[data-env~="is-ios"] .DokaProgressIndicator svg {
    transform-origin: 49% 49%;
  }
}
.DokaRadioGroup .DokaRadioGroupOptions {
  display: flex;
  flex-direction: column;
}
.DokaRadioGroup .DokaRadioGroupOptionGroup + .DokaRadioGroupOptionGroup {
  margin-top: 0.5em;
}
.DokaRadioGroup[data-layout="row"] .DokaRadioGroupOptions {
  flex-direction: row;
}
.DokaRadioGroup label {
  -webkit-tap-highlight-color: transparent;
}
.DokaRangeInput {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24em;
  height: 3.5em;
  margin: auto;
  max-width: 90%;
  touch-action: none;
  --range-input-feather-left: 2em;
  --range-input-feather-right: 2em;
  --range-input-mask-from: 0%;
  --range-input-mask-to: 100%;
  --range-input-mask-color: rgba(0, 0, 0, 0.25);
  --range-input-line-color: var(--color-foreground-15);
  outline: transparent;
}
.DokaRangeInput[data-focus-visible]:after {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 1em);
  height: 2em;
  border-radius: 0.25em;
}
.DokaRangeInputInner {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  contain: strict;
  -webkit-mask: linear-gradient(
    90deg,
    transparent 0,
    #000 var(--range-input-feather-left),
    #000 calc(50% - 3em),
    transparent 50%,
    #000 calc(50% + 3em),
    #000 calc(100% - var(--range-input-feather-right)),
    transparent
  );
  mask: linear-gradient(
    90deg,
    transparent 0,
    #000 var(--range-input-feather-left),
    #000 calc(50% - 3em),
    transparent 50%,
    #000 calc(50% + 3em),
    #000 calc(100% - var(--range-input-feather-right)),
    transparent
  );
}
.DokaRangeInputMeter {
  height: 100%;
}
.DokaRangeInput .DokaRangeInputMeter svg {
  display: block;
  pointer-events: none;
  fill: currentColor;
}
.DokaRangeInput > .DokaRangeInputValue {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: calc(50% - 0.6875em);
  left: calc(50% - 1.75em);
  width: 3.5em;
  height: 1.5em;
  overflow: hidden;
  contain: strict;
  text-align: center;
  font-size: 0.75em;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}
.DokaRangeInput > .DokaRangeInputReset {
  position: absolute;
  left: calc(50% - 1.0625em);
  top: 0;
  margin-top: 0.125em;
  padding: 0;
  z-index: 1;
  width: 2em;
  overflow: hidden;
  color: transparent !important;
  transition: opacity 0.25s;
  opacity: 0.35;
  outline: transparent;
  cursor: var(--button-cursor);
}
.DokaRangeInput > .DokaRangeInputReset:hover {
  opacity: 0.5;
}
.DokaRangeInput > .DokaRangeInputReset[data-focus-visible] {
  opacity: 1;
}
.DokaRangeInput > .DokaRangeInputReset[data-focus-visible]:before {
  content: "";
  pointer-events: none;
  position: absolute;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
  height: 1em;
  width: 1em;
  z-index: 2;
  border-radius: 0.25em;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRangeInput > .DokaRangeInputReset[disabled] {
  opacity: 0.15;
}
.DokaRangeInput > .DokaRangeInputReset:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 20%;
  width: 1px;
  height: 60%;
  background: var(--color-foreground-100);
}
.DokaRangeInputInner[data-value-limited] svg {
  -webkit-mask: linear-gradient(
    90deg,
    var(--range-input-mask-color) var(--range-mask-from),
    #000 var(--range-mask-from),
    #000 var(--range-mask-to),
    var(--range-input-mask-color) var(--range-mask-to)
  );
  mask: linear-gradient(
    90deg,
    var(--range-input-mask-color) var(--range-mask-from),
    #000 var(--range-mask-from),
    #000 var(--range-mask-to),
    var(--range-input-mask-color) var(--range-mask-to)
  );
}
.DokaRangeInputInner[data-value-limited] svg rect {
  x: var(--range-mask-from);
  width: calc(var(--range-mask-to) - var(--range-mask-from));
  fill: var(--range-input-line-color);
}
.DokaRectManipulator {
  --size: 1.25em;
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  touch-action: none;
}
.DokaRectManipulator:not([data-shape="edge"]) {
  width: var(--size);
  height: var(--size);
  z-index: 2;
}
.DokaRectManipulator:not([data-shape="edge"]):after {
  position: absolute;
  left: -0.5em;
  right: -0.5em;
  top: -0.5em;
  bottom: -0.5em;
  border-radius: inherit;
  content: "";
}
.DokaRectManipulator:not([data-shape="edge"])[data-focus-visible]:after {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRectManipulator[data-shape~="circle"] {
  margin-left: calc(var(--size) * (-0.5));
  margin-top: calc(var(--size) * (-0.5));
  line-height: var(--size);
  border-radius: 50%;
  background-color: var(--color-foreground-100);
  box-shadow: 0 0 2px var(--color-background-50);
}
.DokaRectManipulator[data-shape="hook"] {
  border: 3px solid var(--color-foreground-100);
}
.DokaRectManipulator[data-shape="hook"][data-direction*="t"] {
  margin-top: -3px;
  border-bottom: 0;
}
.DokaRectManipulator[data-shape="hook"][data-direction*="r"] {
  border-left: 0;
  margin-left: calc(var(--size) * (-1));
}
.DokaRectManipulator[data-shape="hook"][data-direction*="b"] {
  margin-top: calc(var(--size) * (-1));
  border-top: 0;
}
.DokaRectManipulator[data-shape="hook"][data-direction*="l"] {
  border-right: 0;
  margin-left: -3px;
}
.DokaRectManipulator[data-shape~="edge"] {
  height: 1px;
  width: 1px;
  z-index: 1;
}
.DokaRectManipulator[data-shape~="edge"][data-focus-visible] {
  background-color: var(--color-focus-50);
}
.DokaRectManipulator[data-direction="b"],
.DokaRectManipulator[data-direction="t"] {
  cursor: ns-resize;
  height: var(--size);
  margin-top: calc(var(--size) * (-0.5));
  transform-origin: 0 center;
}
.DokaRectManipulator[data-direction="l"],
.DokaRectManipulator[data-direction="r"] {
  cursor: ew-resize;
  width: var(--size);
  margin-left: calc(var(--size) * (-0.5));
  transform-origin: center 0;
}
.DokaRectManipulator[data-direction="bl"],
.DokaRectManipulator[data-direction="tr"] {
  cursor: nesw-resize;
}
.DokaRectManipulator[data-direction="br"],
.DokaRectManipulator[data-direction="tl"] {
  cursor: nwse-resize;
}
.DokaRoot[data-env~="pointer-coarse"]
  .DokaRectManipulator[data-shape="edge"]:active {
  background-color: var(--color-foreground-5);
}
.DokaRoot[data-env~="pointer-coarse"]
  .DokaRectManipulator:not([data-shape="edge"]):after {
  left: -1em;
  right: -1em;
  top: -1em;
  bottom: -1em;
}
.DokaRoot[data-env~="pointer-coarse"]
  .DokaRectManipulator:not([data-shape="edge"]):active:after {
  background-color: var(--color-foreground-5);
}
.DokaScrollable {
  --scrollable-feather-start-opacity: 0;
  --scrollable-feather-end-opacity: 0;
  --scrollable-feather-size: 2em;
  contain: paint;
  overflow: hidden;
}
.DokaScrollable:focus {
  outline: transparent;
}
.DokaScrollable > div {
  position: relative;
  display: inline-flex;
  vertical-align: top;
}
.DokaScrollable {
  touch-action: none;
}
.DokaScrollable[data-direction="x"] {
  max-width: 100%;
  --mask-angle: 90deg;
}
.DokaScrollable[data-direction="y"] {
  max-height: 100%;
  --mask-angle: 180deg;
}
.DokaScrollable[data-state~="overflows"] {
  -webkit-mask: linear-gradient(
    var(--mask-angle),
    rgba(0, 0, 0, var(--scrollable-feather-start-opacity)),
    #000 var(--scrollable-feather-size),
    #000 calc(100% - var(--scrollable-feather-size)),
    rgba(0, 0, 0, var(--scrollable-feather-end-opacity))
  );
  mask: linear-gradient(
    var(--mask-angle),
    rgba(0, 0, 0, var(--scrollable-feather-start-opacity)),
    #000 var(--scrollable-feather-size),
    #000 calc(100% - var(--scrollable-feather-size)),
    rgba(0, 0, 0, var(--scrollable-feather-end-opacity))
  );
}
.DokaScrollable[data-state~="scrolling"] > div *,
.DokaShapeList {
  pointer-events: none;
}
.DokaShapeList {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  position: absolute;
  top: 1em;
  left: 1em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1;
  border-radius: var(--border-radius);
  color: var(--color-foreground-100);
  background: var(--color-background-70);
  box-shadow: 0 0 0 1px var(--color-foreground-5);
}
.DokaShapeList li + li {
  margin-top: -0.3125em;
}
.DokaShapeList[data-visible="true"] {
  opacity: 1;
}
.DokaShapeList .DokaColorPreview {
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.25em;
  border-radius: 0.25em;
}
.DokaShapeList .DokaShapeListItem {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
  outline: none;
}
.DokaShapeList .DokaShapeListItem > span {
  font-size: 0.75em;
  line-height: 1.75;
  padding: 0 0.325em;
  margin-left: -0.325em;
}
.DokaShapeList .DokaShapeListItem[data-focus-visible] > span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaShapeList[data-visible="true"] .DokaShapeListItem {
  pointer-events: all;
}
.DokaShapeEditor {
  height: 100%;
  width: 100%;
  outline: none;
  touch-action: none;
}
.DokaShapeControls {
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: center;
}
.DokaShapeControlsGroup {
  color: #eee;
  background: #333;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  display: inline-flex;
  pointer-events: all;
  min-height: 1.75em;
}
.DokaShapeControlsGroup + .DokaShapeControlsGroup {
  margin-left: 0.5em;
}
.DokaShapeControlsGroup .DokaButton {
  outline: transparent;
}
.DokaShapeControlsGroup .DokaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaShapeControlsGroup > .DokaButton .DokaButtonLabel {
  display: block;
  line-height: 2.4;
  padding: 0 0.75em;
  font-size: 0.75em;
  transition: background-color 0.1s;
  outline: transparent;
}
.DokaShapeControlsGroup > .DokaButton .DokaButtonLabel:hover {
  background-color: hsla(0, 0%, 100%, 0.15);
}
.DokaShapeControlsGroup .DokaButtonIconOnly {
  width: 1.75em;
  transition: background-color 0.1s;
}
.DokaShapeControlsGroup .DokaButtonIconOnly:hover {
  background-color: hsla(0, 0%, 100%, 0.15);
}
.DokaShapeControlsGroup > button:first-of-type {
  border-radius: 0.25em 0 0 0.25em;
}
.DokaShapeControlsGroup > button:last-of-type {
  border-radius: 0 0.25em 0.25em 0;
}
.DokaShapeControlsGroup > * + * {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}
.DokaShapeControlsGroup svg {
  pointer-events: none;
}
.DokaShapeManipulator {
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  touch-action: none;
  --size: 0.75em;
}
.DokaShapeManipulator:not([data-control="edge"]) {
  margin-left: calc(var(--size) * (-0.5));
  margin-top: calc(var(--size) * (-0.5));
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  border-radius: 9999em;
}
.DokaShapeManipulator:not([data-control="edge"]):after {
  position: absolute;
  left: -0.5em;
  right: -0.5em;
  top: -0.5em;
  bottom: -0.5em;
  content: "";
}
.DokaShapeManipulator:not([data-control="edge"])[data-focus-visible]:after {
  border-radius: inherit;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaShapeManipulator[data-control="point"] {
  background: linear-gradient(
    90deg,
    var(--color-secondary-dark),
    var(--color-secondary)
  );
  box-shadow: 0 0 0 2px #fff, 0 0 4px #000;
  z-index: 3;
}
.DokaShapeManipulator[data-control="edge"] {
  margin-top: calc(var(--size) * (-0.5));
  height: var(--size);
  width: 1px;
  z-index: 1;
  transform-origin: 0 calc(var(--size) * 0.5);
}
.DokaShapeManipulator[data-control="edge"][data-focus-visible] {
  background-color: var(--color-focus-50);
}
.DokaShapeManipulator[data-control="rotate"] {
  border-radius: 9999em;
  background: linear-gradient(90deg, #fff, #ddd);
  box-shadow: 0 0 0 2px var(--color-secondary-dark), 0 0 4px #000;
  z-index: 2;
  cursor: move;
}
.DokaShapePresetSelect {
  position: relative;
  line-height: 1.875;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 1em;
}
.DokaShapePresetSelect label {
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  padding: 0.25em 0.75em;
  cursor: var(--button-cursor);
}
.DokaShapePresetSelect label svg {
  width: 0.75em;
  margin-right: 0.325em;
}
.DokaShapePresetSelect span {
  font-size: 0.6875em;
  font-weight: 400;
}
.DokaShapePresetSelect input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 1;
  border: none;
  font-size: 100px;
  cursor: var(--button-cursor);
}
.DokaShapePresetSelect input:focus + label,
.DokaShapePresetSelect input:hover + label {
  background-color: var(--color-foreground-10);
}
.DokaShapePresetsList {
  display: flex;
  align-items: center;
}
.DokaShapePresetsList .DokaShapePreset {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DokaShapePresetsList .DokaShapePreset img,
.DokaShapePresetsList .DokaShapePreset svg {
  display: block;
  width: 1.75em;
  height: 1.75em;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.3125em;
}
.DokaShapePresetsList .DokaShapePreset .DokaDragButton {
  padding: 0.3125em;
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaShapePresetsList .DokaShapePreset .DokaDragButton:hover {
  background-color: var(--color-foreground-10);
}
.DokaShapePresetsList .DokaShapePreset + .DokaShapePreset {
  margin-left: 0.5em;
}
.DokaShapePresetsPalette {
  width: 100%;
}
.DokaShapePresetsPalette .DokaScrollable:not([data-state~="overflows"]) {
  display: flex;
  justify-content: center;
}
.DokaShapePresetsGroups {
  display: flex;
  order: 2;
  margin-bottom: -0.1875em;
}
.DokaShapePresetsGrouped {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DokaShapePresetsFlat {
  display: flex;
  align-items: center;
  justify-content: center;
}
.DokaShapeStyleEditor {
  position: relative;
  width: 100%;
  min-height: 2.75em;
}
.DokaShapeStyles {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.DokaShapeStyles:not([data-state~="overflows"]) {
  display: flex;
  justify-content: center;
}
.DokaShapeStyleList {
  display: flex;
}
.DokaShapeStyleList .DokaButton,
.DokaShapeStyleList .DokaInput {
  outline: transparent;
}
.DokaShapeStyleList .DokaInput {
  padding: 0 0 0 0.625em;
  font-size: 0.75em;
  line-height: 2.25;
}
.DokaShapeStyleList .DokaShapeStyleLabel {
  line-height: 1.75;
  font-size: 0.625em;
  font-weight: 300;
  padding: 0 0.75em;
  text-transform: lowercase;
  pointer-events: none;
  text-align: center;
  color: var(--color-foreground-50);
  white-space: nowrap;
}
.DokaShapeStyleList > .DokaShapeStyle {
  border-radius: var(--border-radius-round);
}
.DokaShapeStyleList > .DokaShapeStyle + .DokaShapeStyle {
  margin-left: 1em;
}
.DokaShapeStyle {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.DokaShapeStyle .DokaDetailsButton {
  border-radius: inherit;
}
.DokaShapeStyle > .DokaButton:hover,
.DokaShapeStyle > .DokaButton[data-focus-visible] {
  position: relative;
  z-index: 1;
}
.DokaShapeStyle > .DokaColorPickerButton .DokaButtonLabel {
  display: block;
  border-radius: inherit;
}
.DokaShapeStyle > .DokaColorPickerButton {
  display: flex;
  justify-content: center;
}
.DokaShapeStyle .DokaColorPreview {
  border-radius: inherit;
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  background-size: 4px;
  border: 1px solid var(--color-background-100);
  margin-top: -1px;
}
.DokaShapeStyle .DokaColorPreview span {
  border-radius: inherit;
}
.DokaShapeStyle [data-focus-visible] .DokaColorPreview span:before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  content: "";
  z-index: 1;
}
.DokaShapeStyle > .DokaColorPickerButtonStroke .DokaColorPreview span:after {
  position: absolute;
  left: 6px;
  top: 6px;
  right: 6px;
  bottom: 6px;
  background: var(--color-background-100);
  border-radius: inherit;
  content: "";
}
.DokaShapeStyle > .DokaDropdownButton .DokaButtonLabel,
.DokaShapeStyle > .DokaRadioGroup .DokaButtonLabel,
.DokaShapeStyle > .DokaRadioGroupOption label {
  display: block;
  line-height: 2.25;
  padding: 0 1em;
  font-size: 0.6875em;
}
.DokaShapeStyle > .DokaButton:not(.DokaColorPickerButton)[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaShapeStyle > .DokaDropdownButton {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color 0.1s;
}
.DokaShapeStyle > .DokaDropdownButton:hover {
  background-color: var(--color-foreground-10);
}
.DokaShapeStyle > .DokaDropdownButton .DokaButtonLabel:after {
  content: "▼";
  font-size: 0.6875em;
  margin-left: 0.75em;
  position: relative;
  top: -0.125em;
  margin-right: -0.25em;
  pointer-events: none;
}
.DokaShapeStyle > .DokaRadioGroup > .DokaRadioGroupOptions {
  flex-direction: row;
}
.DokaShapeStyle > .DokaRadioGroup > .DokaRadioGroupOptions label {
  cursor: var(--button-cursor);
  display: flex;
  height: 1.5em;
  align-items: center;
  padding: 0 0.25em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color 0.1s;
}
.DokaShapeStyle > .DokaRadioGroup > .DokaRadioGroupOptions label:hover {
  background-color: var(--color-foreground-20);
}
.DokaShapeStyle > .DokaRadioGroup > .DokaRadioGroupOptions svg {
  width: 1.125em;
  height: 1.125em;
}
.DokaShapeStyle
  > .DokaRadioGroup
  > .DokaRadioGroupOptions
  .DokaRadioGroupOption:first-of-type
  label {
  padding-left: 0.5em;
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}
.DokaShapeStyle
  > .DokaRadioGroup
  > .DokaRadioGroupOptions
  .DokaRadioGroupOption:last-of-type
  label {
  padding-right: 0.5em;
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}
.DokaShapeStyle
  > .DokaRadioGroup
  > .DokaRadioGroupOptions
  [data-selected="true"]
  label {
  background-color: var(--color-foreground-10);
}
.DokaSlider {
  position: relative;
}
.DokaSlider .DokaSliderKnob {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  width: 1em;
  height: 1em;
  margin-left: -0.5em;
  margin-top: -0.5em;
  border-radius: 9999em;
  background-color: var(--color-background-50);
  visibility: visible;
}
.DokaSlider .DokaSliderKnobController,
.DokaSlider .DokaSliderTrack {
  pointer-events: none;
  position: absolute;
  top: 0.5em;
  bottom: 0.5em;
  left: 0.5em;
  right: 0.5em;
}
.DokaSlider .DokaSliderKnobController {
  visibility: hidden;
}
.DokaSlider .DokaSliderTrack {
  border-radius: 9999em;
}
.DokaSlider input[type="range"] {
  -webkit-appearance: none;
  height: 1.5em;
  display: block;
  margin-left: 0.5em;
  width: calc(100% - 1em);
}
.DokaSlider input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}
.DokaSlider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
}
.DokaSlider input[type="range"]::-moz-range-thumb {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
}
.DokaSlider input[type="range"]::-ms-thumb {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
}
.DokaSlider input[type="range"]::-ms-ticks-before,
.DokaSlider input[type="range"]::-ms-tooltip {
  display: none;
}
.DokaSlider input[type="range"]::-ms-ticks-after {
  display: none;
}
.DokaSlider input[type="range"]::-ms-track {
  color: transparent;
  border: none;
  background: transparent;
}
.DokaSlider input[type="range"]::-ms-fill-lower,
.DokaSlider input[type="range"]::-ms-fill-upper {
  background: transparent;
}
.DokaSlider input[type="range"]:focus {
  outline: transparent;
}
.DokaSlider
  input[type="range"][data-focus-visible]
  ~ .DokaSliderKnobController
  .DokaSliderKnob:after {
  position: absolute;
  left: -0.3125em;
  right: -0.3125em;
  top: -0.3125em;
  bottom: -0.3125em;
  border-radius: inherit;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  pointer-events: none;
}
.DokaTabList {
  display: flex;
  justify-content: center;
}
.DokaTabList [role="tab"] {
  display: flex;
  align-items: center;
  flex-direction: column;
  outline: transparent;
  cursor: var(--button-cursor);
  -webkit-tap-highlight-color: transparent;
}
.DokaTabList [role="tab"] > * {
  transition: filter 0.2s ease-out;
}
.DokaTabList [role="tab"][disabled] > * {
  filter: var(--filter-disabled);
}
.DokaTabPanels {
  position: relative;
  flex: 1;
}
.DokaTabPanel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.DokaTabPanel:not([hidden]) {
  z-index: 2;
  outline: transparent;
}
.DokaTabPanel[hidden] {
  display: block !important;
  z-index: 1;
  pointer-events: none;
}
.DokaTabPanel[data-inert="true"] {
  visibility: hidden;
}
.DokaTabPanel[data-inert="true"] [data-inert] {
  visibility: hidden !important;
}
.DokaToolbar {
  display: flex;
  justify-content: center;
  margin-left: 1em;
  margin-right: 1em;
  padding-top: 1em;
}
.DokaToolbar .DokaToolbarInner {
  display: inline-flex;
}
.DokaToolbar[data-layout="compact"] .DokaToolbarContentWide,
.DokaToolbar[data-overflow="overflow"] .DokaToolbarContentOptional {
  display: none;
}
.DokaToolbar .DokaButton {
  border-radius: var(--border-radius-round);
  outline: transparent;
  padding: 0.3125em 0.75em;
  transition: background-color 0.1s ease-out, color 0.1s ease-out,
    box-shadow 0.1s ease-out;
}
.DokaToolbar .DokaButton:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaToolbar .DokaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaToolbar .DokaButton svg {
  width: 1em;
}
.DokaToolbar .DokaButtonLabel {
  font-size: 0.75em;
}
.DokaToolbar .DokaDetailsPanel {
  z-index: 3;
}
.DokaToolbar .DokaToolbarInner > * {
  margin: 0 0.5em;
}
.DokaUtilMain {
  padding: 1em;
  min-height: 1px;
}
.DokaUtilMain,
.DokaUtilMain > [slot] {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.DokaUtilMain > [slot] {
  align-items: center;
}
.DokaUtilFooter {
  padding: 0 0 1em;
}
.DokaUtilFooter > [slot]:empty {
  height: 1em;
}
.DokaUtilFooter > [slot] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}
.DokaUtilFooter .DokaRangeInput {
  margin-top: -0.25em;
}
.DokaUtilFooter > [slot] > .DokaControlList,
.DokaUtilFooter > [slot] > .DokaControlListScroller {
  order: 1;
  margin-bottom: -0.1875em;
}
.DokaUtilFooter .DokaControlList .DokaControlListOption label,
.DokaUtilFooter .DokaControlList [role="tab"] {
  flex-direction: row;
  line-height: 1;
  padding: 0 0.75em;
  min-height: 1.75em;
  border-radius: var(--border-radius-round);
  transition: background-color 0.1s ease-out, color 0.1s ease-out,
    filter 0.2s ease-out;
  box-shadow: inset 0 0 0 1px var(--color-foreground-0);
  cursor: var(--button-cursor);
}
.DokaUtilFooter .DokaControlList .DokaControlListOption label span,
.DokaUtilFooter .DokaControlList [role="tab"] span {
  font-size: 0.75em;
  font-weight: 400;
}
.DokaUtilFooter .DokaControlList .DokaControlListOption label svg,
.DokaUtilFooter .DokaControlList [role="tab"] svg {
  width: 0.875em;
  font-size: 0.875em;
}
.DokaUtilFooter
  .DokaControlList
  .DokaControlListOption
  label
  svg:not(:only-child),
.DokaUtilFooter .DokaControlList [role="tab"] svg:not(:only-child) {
  margin-left: -0.25em;
  margin-right: 0.375em;
}
.DokaUtilFooter
  .DokaControlList
  .DokaControlListOption
  label[aria-selected="true"],
.DokaUtilFooter .DokaControlList [role="tab"][aria-selected="true"] {
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
  background-color: var(--color-foreground-10);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaUtilFooter .DokaControlList .DokaControlListOption label:hover,
.DokaUtilFooter .DokaControlList [role="tab"]:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}
.DokaUtilFooter
  .DokaControlList
  .DokaControlListOption
  label[data-focus-visible],
.DokaUtilFooter .DokaControlList [role="tab"][data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaUtilFooter .DokaControlListOption [slot],
.DokaUtilFooter .DokaControlListOption label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DokaUtilFooter .DokaControlListOption[data-selected="true"] label {
  background-color: var(--color-foreground-10);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaUtilFooter .DokaControlListOption input[data-focus-visible] + label {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaUtilFooter .DokaControlListOption input[disabled] + label {
  filter: var(--filter-disabled);
}
.DokaUtilFooter
  .DokaControlList
  .DokaControlListOption
  + .DokaControlListOption,
.DokaUtilFooter .DokaControlList > li + li {
  margin-left: 0.5em;
}
.DokaUtilFooter .DokaControlPanels {
  position: relative;
  width: 100%;
  max-width: 30em;
  min-height: 3.375em;
  margin: 0 auto;
  flex: auto;
}
.DokaUtilFooter .DokaControlPanel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, visibility 0.3s linear;
  transition-delay: 0.1s;
}
.DokaUtilFooter .DokaControlPanel[hidden] {
  display: block !important;
  visibility: hidden;
  transition-delay: 0s;
  pointer-events: none;
  opacity: 0;
}
.DokaUtilFooter .DokaControlList[data-layout="compact"] > * + * {
  margin-left: -1px;
}
.DokaUtilFooter .DokaControlList[data-layout="compact"] > * [role="tab"] {
  padding-left: 0.625em;
  padding-right: 0.625em;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaUtilFooter
  .DokaControlList[data-layout="compact"]
  > :only-child
  [role="tab"] {
  border-radius: var(--border-radius-round);
}
.DokaUtilFooter
  .DokaControlList[data-layout="compact"]
  > :first-child:not(:only-child)
  [role="tab"] {
  padding-left: 0.75em;
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}
.DokaUtilFooter
  .DokaControlList[data-layout="compact"]
  > :last-child:not(:only-child)
  [role="tab"] {
  padding-right: 0.75em;
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}
.DokaRoot[data-env~="overlay"] .DokaUtilFooter {
  margin-bottom: 0.75em;
}
.DokaRoot[data-env~="overlay"] .DokaUtilFooter .DokaRangeInput {
  margin-top: 0;
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 0 1px var(--color-background-15),
    0 0.0625em 0.125em rgba(0, 0, 0, 0.25), 0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}
.doka-image-editor,
doka-image-editor {
  --color-primary: #ffd843;
  --color-primary-dark: #ffc343;
  --color-primary-text: #000;
  --color-secondary: #03a9f4;
  --color-secondary-dark: #046bbf;
  --color-focus: 4, 107, 191;
  --color-focus-100: rgba(var(--color-focus), 1);
  --color-focus-50: rgba(var(--color-focus), 0.5);
  --color-focus-25: rgba(var(--color-focus), 0.25);
  --color-error: 255, 87, 34;
  --color-error-100: rgb(var(--color-error));
  --color-error-75: rgba(var(--color-error), 0.75);
  --color-error-50: rgba(var(--color-error), 0.5);
  --color-error-25: rgba(var(--color-error), 0.25);
  --color-foreground: 0, 0, 0;
  --color-background: 255, 255, 255;
  --color-foreground-100: rgba(var(--color-foreground), 1);
  --color-foreground-95: rgba(var(--color-foreground), 0.95);
  --color-foreground-90: rgba(var(--color-foreground), 0.9);
  --color-foreground-80: rgba(var(--color-foreground), 0.8);
  --color-foreground-70: rgba(var(--color-foreground), 0.7);
  --color-foreground-60: rgba(var(--color-foreground), 0.6);
  --color-foreground-50: rgba(var(--color-foreground), 0.5);
  --color-foreground-40: rgba(var(--color-foreground), 0.4);
  --color-foreground-30: rgba(var(--color-foreground), 0.3);
  --color-foreground-20: rgba(var(--color-foreground), 0.25);
  --color-foreground-15: rgba(var(--color-foreground), 0.2);
  --color-foreground-10: rgba(var(--color-foreground), 0.15);
  --color-foreground-5: rgba(var(--color-foreground), 0.075);
  --color-foreground-3: rgba(var(--color-foreground), 0.05);
  --color-foreground-1: rgba(var(--color-foreground), 0.02);
  --color-foreground-0: rgba(var(--color-foreground), 0);
  --color-background-100: rgba(var(--color-background), 1);
  --color-background-95: rgba(var(--color-background), 0.95);
  --color-background-90: rgba(var(--color-background), 0.9);
  --color-background-80: rgba(var(--color-background), 0.8);
  --color-background-70: rgba(var(--color-background), 0.7);
  --color-background-60: rgba(var(--color-background), 0.6);
  --color-background-50: rgba(var(--color-background), 0.5);
  --color-background-40: rgba(var(--color-background), 0.4);
  --color-background-30: rgba(var(--color-background), 0.3);
  --color-background-20: rgba(var(--color-background), 0.2);
  --color-background-15: rgba(var(--color-background), 0.15);
  --color-background-10: rgba(var(--color-background), 0.1);
  --color-background-5: rgba(var(--color-background), 0.05);
  --color-background-3: rgba(var(--color-background), 0.03);
  --color-background-1: rgba(var(--color-background), 0.01);
  --color-background-0: rgba(var(--color-background), 0);
  --button-cursor: pointer;
  --font-size: 16px;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --backdrop-filter-bright: brightness(110%) saturate(180%) blur(10px);
  --backdrop-filter-dark: brightness(90%) saturate(180%) blur(10px);
  --border-radius-round: 9999em;
  --border-radius: 0.625em;
  --filter-disabled: grayscale(95%) opacity(40%);
  --editor-inset-top: 0px;
  --editor-inset-bottom: 0px;
  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;
}
.doka-image-editor,
.DokaRootWrapper,
doka-image-editor {
  display: block;
}
.DokaRootWrapper {
  height: 100%;
}
.DokaRoot {
  --nav-group-margin: 0.5em;
  --editor-calculated-inset-top: calc(
    var(--safe-area-inset-top) + var(--editor-inset-top)
  );
  --editor-calculated-inset-bottom: calc(
    var(--safe-area-inset-bottom) + var(--editor-inset-bottom)
  );
  box-sizing: border-box;
  width: var(--editor-width, 100%);
  height: var(--editor-height, 100%);
  padding-top: var(--editor-calculated-inset-top);
  padding-bottom: var(--editor-calculated-inset-bottom);
  max-width: var(--editor-max-width, none);
  max-height: var(--editor-max-height, none);
  position: relative;
  overflow: hidden;
  contain: strict;
  display: grid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: normal;
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: 450;
  touch-action: manipulation;
  text-align: left;
  text-transform: none;
  text-rendering: optimizeLegibility;
  direction: ltr;
  color: var(--color-foreground-90);
  background-color: var(--color-background-100);
  transition: background-color 1ms, color 0.15s ease-in-out;
}
.DokaRoot * {
  box-sizing: content-box;
}
.DokaRoot button,
.DokaRoot fieldset,
.DokaRoot input,
.DokaRoot legend {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  width: auto;
  text-decoration: none;
}
.DokaRoot button[disabled] {
  pointer-events: none;
}
.DokaRoot canvas {
  display: block;
}
.DokaRoot svg {
  display: inline-block;
  width: auto;
  height: auto;
}
.DokaRoot p {
  margin: 0;
}
.DokaRoot li,
.DokaRoot ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.DokaRoot a {
  color: inherit;
  text-decoration: none;
}
.DokaRoot .implicit {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: polygon(0 0, 0 0, 0 0);
  clip-path: polygon(0 0, 0 0, 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.DokaRoot .DokaStage {
  display: flex;
  align-self: stretch;
  flex: 1;
}
.DokaRoot .DokaStage[tabindex="-1"] {
  outline: none;
}
.DokaRoot .DokaRootPortal {
  position: absolute;
  left: 0;
  top: 0;
}
.DokaRoot > .DokaCanvas {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.DokaRoot > .DokaCanvas,
.DokaRoot > .DokaCanvas:after {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.DokaRoot > .DokaCanvas:after {
  content: "";
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
}
.DokaRoot > .DokaStatus {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-background-90);
}
.DokaRoot > .DokaStatus > p {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  transition: opacity 0.5s ease-out;
}
.DokaRoot > .DokaStatus .DokaStatusButton,
.DokaRoot > .DokaStatus .DokaStatusIcon {
  position: absolute;
}
.DokaRoot > .DokaStatus .DokaStatusButton svg,
.DokaRoot > .DokaStatus .DokaStatusIcon svg {
  width: 1.25em;
  height: 1.25em;
}
.DokaRoot > .DokaStatus .DokaStatusIcon svg {
  font-size: 0.875em;
  margin-top: 0.25em;
  margin-left: 0.5em;
}
.DokaRoot > .DokaStatus .DokaStatusButton .DokaButton {
  outline: transparent;
  margin-left: 0.75em;
  font-size: 0.875em;
  border-radius: var(--border-radius-round);
  background-color: var(--color-foreground-5);
  padding: 0.25em;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.DokaRoot
  > .DokaStatus
  .DokaStatusButton
  .DokaButton:not([disabled])[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRoot > .DokaStatus .DokaStatusButton .DokaButton:not([disabled]):hover {
  background-color: var(--color-foreground-10);
}
.DokaRoot > .DokaStatus .DokaStatusMessage {
  position: absolute;
  font-size: 0.875em;
}
.DokaRoot > .DokaStatus .DokaStatusProgress {
  position: absolute;
  left: 0;
}
.DokaRoot > .DokaStatus .DokaProgressIndicator {
  margin-left: 0.5em;
}
.DokaRoot > .DokaNav {
  position: relative;
  z-index: 3;
}
.DokaRoot > .DokaNav:empty() {
  display: none !important;
}
.DokaRoot > .DokaNav .DokaButton {
  outline: transparent;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.DokaRoot > .DokaNav .DokaButton svg {
  width: 1em;
  transition: opacity 0.1s ease-out;
}
.DokaRoot > .DokaNav .DokaButton > span {
  justify-content: center;
}
.DokaRoot > .DokaNav .DokaButtonIconOnly .DokaButtonInner,
.DokaRoot > .DokaNav .DokaDropdownIconOnly .DokaButtonInner {
  width: 1.75em;
  height: 1.75em;
}
.DokaRoot > .DokaNav .DokaButton[disabled] svg {
  opacity: 0.25;
}
.DokaRoot
  > .DokaNav
  .DokaButton:not(.DokaButtonExport):not([disabled])[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRoot > .DokaNav .DokaButton:not(.DokaButtonExport):not([disabled]):hover {
  background-color: var(--color-foreground-10);
}
.DokaRoot .DokaButtonExport {
  color: var(--color-primary-text);
  background-color: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}
.DokaRoot .DokaButtonExport:hover {
  background-color: var(--color-primary-dark);
}
.DokaRoot .DokaButtonExport[data-focus-visible] {
  color: var(--color-primary-text);
  background-color: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5),
    0 0 0 3px var(--color-focus-50);
  background-color: var(--color-primary-dark);
}
.DokaRoot .DokaButtonExport:not(.DokaButtonIconOnly) {
  flex: 1;
  padding: 0 0.75em;
}
.DokaRoot .DokaButtonExport:not(.DokaButtonIconOnly) .DokaButtonLabel {
  line-height: 2.25;
}
.DokaRoot .DokaButtonExport:not(.DokaButtonIconOnly) .DokaButtonInner {
  font-size: 0.75em;
}
.DokaRoot > .DokaNavMain {
  display: flex;
  overflow: hidden;
}
.DokaRoot > .DokaNavMain [role="tab"] svg {
  align-self: center;
  width: 1.5em;
}
.DokaRoot > .DokaNavMain [role="tab"] span,
.DokaRoot > .DokaNavMain [role="tab"] svg {
  pointer-events: none;
}
.DokaRoot > .DokaNavMain [role="tab"] svg + span {
  margin-top: 0.75em;
}
.DokaRoot > .DokaNavMain [role="tab"] span {
  font-size: 0.875em;
}
.DokaRoot > .DokaNavMain [role="tab"] {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color 0.1s ease-out, color 0.1s ease-out,
    box-shadow 0.1s ease-out;
}
.DokaRoot > .DokaNavMain [role="tab"]:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}
.DokaRoot > .DokaNavMain [role="tab"][aria-selected="true"] {
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
  background-color: var(--color-foreground-10);
}
.DokaRoot > .DokaNavMain [role="tab"][data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRoot > .DokaNavTools {
  max-width: 100%;
  box-sizing: border-box;
  pointer-events: none;
}
.DokaRoot > .DokaNavTools:empty {
  display: none;
}
.DokaRoot > .DokaNavTools,
.DokaRoot > .DokaNavTools .DokaNavGroup,
.DokaRoot > .DokaNavTools .DokaNavSet {
  display: flex;
  align-items: center;
  justify-content: center;
}
.DokaRoot > .DokaNavTools .DokaButton,
.DokaRoot > .DokaNavTools .DokaNavSet {
  pointer-events: all;
}
.DokaRoot > .DokaNavTools .DokaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRoot .DokaNavGroup {
  margin: 0 var(--nav-group-margin);
}
.DokaRoot .DokaNavGroup > * {
  border-radius: var(--border-radius-round);
}
.DokaRoot .DokaNavGroup > * + * {
  margin-left: 0.5em;
}
.DokaRoot .DokaNavGroup:first-of-type {
  margin-right: auto;
  margin-left: 0;
}
.DokaRoot .DokaNavGroup:last-of-type {
  margin-left: auto;
  margin-right: 0;
}
.DokaRoot .DokaNavSet {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}
.DokaRoot .DokaNavSet > :not(:only-child) {
  border: 1px solid var(--color-foreground-3);
  border-top: 0;
  border-bottom: 0;
}
.DokaRoot .DokaNavSet > :first-child:not(:only-child) {
  border-left: 0;
}
.DokaRoot .DokaNavSet > :last-child:not(:only-child) {
  border-right: 0;
}
.DokaRoot .DokaNavSet > * + * {
  margin-left: -1px;
}
.DokaRoot .DokaNavSet > .DokaButton:hover,
.DokaRoot .DokaNavSet > .DokaButton[data-focus-visible] {
  position: relative;
  z-index: 1;
}
.DokaRoot .DokaNavSet > :only-child {
  border-radius: var(--border-radius-round);
}
.DokaRoot .DokaNavSet > :first-child:not(:only-child) .DokaButtonInner {
  padding: 0 0.125em 0 0.25em;
}
.DokaRoot .DokaNavSet > :first-child:not(:only-child) {
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}
.DokaRoot .DokaNavSet > :last-child:not(:only-child) .DokaButtonInner {
  padding: 0 0.25em 0 0.125em;
}
.DokaRoot .DokaNavSet > :last-child:not(:only-child) {
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}
.DokaRoot[data-env~="overlay"] .DokaNavSet {
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  box-shadow: inset 0 0 0 1px var(--color-background-15),
    0 0.0625em 0.125em rgba(0, 0, 0, 0.25), 0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}
.DokaRoot[data-env~="overlay"]
  .DokaNavSet
  .DokaButton:not(.DokaButtonExport):not([disabled]):hover,
.DokaRoot[data-env~="overlay"] .DokaNavSet > * {
  border-color: var(--color-background-15);
}
.DokaRoot[data-env~="overlay"] .DokaNavGroup > .DokaButton {
  box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.25),
    0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}
.DokaRoot[data-env~="overlay"] .DokaNavGroup .DokaButtonExport {
  box-shadow: inset 0 0 0.125em rgba(0, 0, 0, 0.25),
    0 0.0625em 0.125em rgba(0, 0, 0, 0.25), 0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}
.DokaRoot .DokaNavGroupFloat {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}
.DokaRoot .DokaNavGroupFloat > * {
  pointer-events: all;
}
.DokaRoot .DokaUtilFooter .DokaScrollable > div {
  padding-left: 1em;
  padding-right: 1em;
}
.DokaRoot > .DokaMain {
  max-width: 100vw;
}
.DokaRoot:not([data-env~="has-toolbar"]) .DokaUtilHeader {
  padding-top: 0;
  padding-bottom: 1em;
  margin-bottom: -1em;
}
.DokaRoot[data-env~="landscape"] {
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  grid-template-columns: auto;
}
.DokaRoot[data-env~="landscape"] > .DokaNavTools {
  grid-row: 1;
  grid-column: 1;
  padding: 1em 1em 0;
  z-index: 3;
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain {
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 1em;
  top: calc(1em + var(--editor-calculated-inset-top));
  bottom: calc(1em + var(--editor-calculated-inset-bottom));
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain .DokaTabList {
  flex-direction: column;
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain .DokaTabList li {
  display: flex;
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain .DokaTabList li + li {
  margin: 0.5em 0 0;
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain [role="tab"] {
  flex: 1;
  width: 4em;
  height: 4em;
  border-radius: var(--border-radius);
  justify-content: center;
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain [role="tab"] span {
  font-size: 0.6875em;
  margin-top: 0.5em;
}
.DokaRoot[data-env~="landscape"] > .DokaNavMain [role="tab"] svg {
  width: 1em;
  margin-top: 0.25em;
}
.DokaRoot[data-env~="landscape"][data-env~="has-toolbar"] > .DokaNavMain {
  top: calc(4em + var(--editor-calculated-inset-top));
  bottom: calc(4em + var(--editor-calculated-inset-bottom));
}
.DokaRoot[data-env~="landscape"][data-env~="has-limited-space"] > .DokaNavMain {
  align-items: flex-start;
  bottom: calc(1em + var(--editor-calculated-inset-bottom));
}
.DokaRoot[data-env~="landscape"] > .DokaMain {
  grid-row: 2;
  grid-column: 1;
}
.DokaRoot[data-env~="landscape"][data-env~="has-navigation"] {
  grid-template-columns: 6em auto;
}
.DokaRoot[data-env~="landscape"][data-env~="has-navigation"]
  .DokaNavGroupFloat {
  margin: 0 0 0 6em;
}
.DokaRoot[data-env~="landscape"][data-env~="has-navigation"] > .DokaNavTools {
  grid-column: 1 / span 2;
}
.DokaRoot[data-env~="landscape"][data-env~="has-navigation"] > .DokaMain {
  grid-column: 2;
}
.DokaRoot[data-env~="portrait"] {
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: auto;
}
.DokaRoot[data-env~="portrait"] > * {
  grid-column: 1;
}
.DokaRoot[data-env~="portrait"] > .DokaNavTools {
  grid-row: 1;
  padding: 0.75em 0.75em 0;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain {
  grid-row: 3;
  margin-bottom: 0.75em;
  justify-content: center;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain .DokaTabList {
  padding: 0 0.875em;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain li + li {
  margin-left: 0.5em;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain[data-state~="overflows"] {
  overflow: visible;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain [role="tab"] {
  flex: 1;
  width: 4em;
  height: 4em;
  border-radius: var(--border-radius);
  justify-content: center;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain [role="tab"] span {
  font-size: 0.6875em;
  margin-top: 0.5em;
}
.DokaRoot[data-env~="portrait"] > .DokaNavMain [role="tab"] svg {
  width: 1em;
  margin-top: 0.25em;
}
.DokaRoot[data-env~="portrait"] > .DokaMain {
  grid-row: 2;
  grid-column: 1;
}
.DokaRoot[data-env~="landscape"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"] {
  flex: 1;
  flex-direction: row;
  width: auto;
  height: auto;
  border-radius: var(--border-radius);
  justify-content: flex-start;
  align-items: center;
  padding: 0.625em 0;
  width: 6em;
}
.DokaRoot[data-env~="landscape"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"]
  span {
  font-size: 0.6875em;
  margin: 0 0.625em 0 0;
  line-height: 0;
}
.DokaRoot[data-env~="landscape"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"]
  svg {
  margin: 0 0.5em 0 0.625em;
  width: 1em;
}
.DokaRoot[data-env~="landscape"][data-env~="is-compact"][data-env~="has-navigation"] {
  grid-template-columns: 8em auto;
}
.DokaRoot[data-env~="landscape"][data-env~="is-compact"][data-env~="has-navigation"]
  .DokaNavGroupFloat {
  margin: 0 0 0 8em;
}
.DokaRoot[data-env~="portrait"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"] {
  padding: 0;
  font-size: 0.625em;
  width: 4em;
  height: 4em;
  justify-content: center;
  border-radius: 9999em;
}
.DokaRoot[data-env~="portrait"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"]
  span {
  visibility: hidden;
  font-size: 0;
  margin: 0;
}
.DokaRoot[data-env~="portrait"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"]
  svg {
  margin-top: 0;
  width: 1.6em;
}
.DokaRoot[data-env~="portrait"][data-env~="is-compact"]
  > .DokaNavMain
  [role="tab"]
  svg
  [stroke-width] {
  stroke-width: 0.15em;
}
.DokaRoot[data-env~="portrait"][data-env~="narrow"] {
  --nav-group-margin: 0.25em;
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: auto;
}
.DokaRoot[data-env~="portrait"][data-env~="narrow"] > * {
  grid-column: 1;
}
.DokaRoot[data-env~="portrait"][data-env~="narrow"]
  .DokaUtilFooter
  .DokaControlList
  .DokaControlListOption
  span,
.DokaRoot[data-env~="portrait"][data-env~="narrow"]
  .DokaUtilFooter
  .DokaControlList
  [role="tab"]
  span {
  font-size: 0.6875em;
}
.DokaRoot[data-env~="portrait"][data-env~="narrow"]
  .DokaToolbar
  .DokaToolbarInner
  > * {
  margin: 0 0.25em;
}
.DokaRoot[data-env~="portrait"][data-env~="narrow"][data-env~="short"]
  .DokaNavGroupFloat {
  position: static;
}
.DokaRoot[data-env~="overlay"] {
  grid-template-rows: auto;
  grid-template-columns: auto;
}
.DokaRoot[data-env~="overlay"] .DokaNav {
  z-index: 3;
}
.DokaRoot[data-env~="overlay"] .DokaMain {
  grid-row: 1;
  grid-column: 1;
}
.DokaRoot[data-env~="overlay"] .DokaUtilMain {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: -1;
}
.DokaRoot[data-env~="overlay"] .DokaUtilFooter,
.DokaRoot[data-env~="overlay"] .DokaUtilHeader {
  position: absolute;
  left: 0;
  right: 0;
}
.DokaRoot[data-env~="overlay"] .DokaUtilHeader {
  top: 0;
}
.DokaRoot[data-env~="overlay"] .DokaUtilFooter {
  bottom: 0;
}
.DokaRoot[data-env~="overlay"] .DokaNavMain {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: none;
}
.DokaRoot[data-env~="overlay"] .DokaNavTools {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
.DokaRoot[data-env~="has-swipe-navigation"] .DokaUtilMain {
  padding-left: 2em;
  padding-right: 2em;
}
.DokaModal {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 2147483646;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-95);
}
.DokaModal > .DokaRoot[data-env~="is-centered"] {
  box-shadow: 0 0 0 1px var(--color-foreground-3);
}
.DokaModal[style*="--viewport-pad-footer:1"] {
  box-sizing: border-box;
}
.DokaImageInfo {
  pointer-events: none;
}
.DokaImageInfo p {
  width: 8em;
  height: 1.25em;
  contain: strict;
  overflow: hidden;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  text-align: right;
  line-height: 1;
  font-size: 0.75em;
}
.DokaPanel[data-util="crop"] .DokaStage {
  touch-action: none;
}
.DokaPanel[data-util="crop"] .DokaImageInfo {
  position: absolute;
  right: 1em;
  bottom: 1em;
}
.DokaPanel[data-util="crop"] .DokaButtonCenter {
  --size: 3em;
  position: absolute;
  left: 0;
  top: 0;
  width: var(--size);
  height: var(--size);
  z-index: 1;
  line-height: 0;
  margin-top: calc(var(--size) * (-0.5));
  margin-left: calc(var(--size) * (-0.5));
  overflow: hidden;
  color: #000;
  background: hsla(0, 0%, 100%, 0.8);
  border-radius: 50%;
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  outline: none;
}
.DokaPanel[data-util="crop"] .DokaButtonCenter[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaRoot[data-env~="short"] .DokaPanel[data-util="crop"] .DokaUtilFooter {
  padding-bottom: 0.5em;
}
.DokaPanel[data-util="filter"] .DokaScrollable {
  width: 100%;
  max-width: 40em;
}
.DokaPanel[data-util="filter"]
  [data-layout="row"]
  > .DokaRadioGroupOptions
  > li {
  margin: 0 0.5em;
}
.DokaPanel[data-util="filter"]
  [data-layout="row"]
  > .DokaRadioGroupOptions
  > li:first-child {
  margin-left: 0;
}
.DokaPanel[data-util="filter"]
  [data-layout="row"]
  > .DokaRadioGroupOptions
  > li:last-child {
  margin-right: 0;
}
.DokaPanel[data-util="filter"] .DokaRadioGroupOptionGroupLabel {
  width: 1px;
  height: 1px;
  position: absolute;
  font-size: 0;
  pointer-events: none;
}
.DokaPanel[data-util="filter"] .DokaFilterPreview {
  border-radius: 0;
  transition: border-radius 1ms;
}
.DokaPanel[data-util="filter"] .DokaFilterOption {
  border-radius: 0;
}
.DokaPanel[data-util="filter"]
  .DokaRadioGroupOptions
  .DokaRadioGroupOption:first-of-type
  .DokaFilterPreview {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.DokaPanel[data-util="filter"]
  .DokaRadioGroupOptions
  .DokaRadioGroupOption:first-of-type
  .DokaFilterOption {
  border-bottom-left-radius: var(--border-radius);
}
.DokaPanel[data-util="filter"]
  .DokaRadioGroupOptions
  .DokaRadioGroupOption:last-of-type
  .DokaFilterPreview {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.DokaPanel[data-util="filter"]
  .DokaRadioGroupOptions
  .DokaRadioGroupOption:last-of-type
  .DokaFilterOption {
  border-bottom-right-radius: var(--border-radius);
}
.DokaPanel[data-util="filter"] .DokaRadioGroupOptionGroup {
  display: flex;
  flex-direction: column-reverse;
}
.DokaPanel[data-util="filter"] .DokaFilterOption {
  cursor: var(--button-cursor);
  margin-bottom: 1em;
}
.DokaPanel[data-util="filter"] .DokaFilterOption span {
  display: block;
  text-align: center;
  padding: 0 0.3125em;
  line-height: 1.75;
  max-width: 100%;
  font-size: 0.625em;
  font-weight: 400;
  margin-left: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.DokaPanel[data-util="filter"] .DokaFilterPreview:before {
  transition: background-color 0.1s, color 0.1s;
}
.DokaPanel[data-util="filter"]
  [data-selected="true"]
  .DokaFilterOption
  .DokaFilterPreview {
  position: relative;
  z-index: 1;
}
.DokaPanel[data-util="filter"]
  [data-selected="true"]
  .DokaFilterOption
  .DokaFilterPreview:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  border-radius: inherit;
  background-color: var(--color-primary);
}
.DokaPanel[data-util="filter"]
  [data-focus-visible]
  + label
  > .DokaFilterOption
  span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: 0.25em;
}
.DokaPanel[data-util="filter"] .DokaFilterOption,
.DokaPanel[data-util="filter"] .DokaFilterPreview {
  width: 3.75em;
  height: 4.5em;
}
.DokaRoot[data-env~="is-compact"]
  .DokaPanel[data-util="filter"]
  .DokaUtilFooter
  > div {
  padding: 0;
}
.DokaRoot[data-env~="is-compact"]
  .DokaPanel[data-util="filter"]
  .DokaFilterOption,
.DokaRoot[data-env~="is-compact"]
  .DokaPanel[data-util="filter"]
  .DokaFilterPreview {
  width: 2.875em;
  height: 3.5em;
}
.DokaRoot[data-env~="is-compact"]
  .DokaPanel[data-util="filter"]
  .DokaFilterOption
  span {
  font-size: 0.5em;
}
.DokaPanel[data-util="resize"] form {
  display: flex;
  justify-content: center;
  padding: 0 1em;
}
.DokaPanel[data-util="resize"] .DokaFormInner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DokaPanel[data-util="resize"] .DokaFormInner > button {
  position: absolute;
  left: 100%;
  margin-left: 1em;
}
.DokaPanel[data-util="resize"] .DokaFieldsetInner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.DokaPanel[data-util="resize"] input[type="checkbox"] + label {
  padding: 0 0.125em;
  outline: transparent;
  display: block;
  cursor: pointer;
}
.DokaPanel[data-util="resize"]
  input[type="checkbox"][data-focus-visible]
  + label {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}
.DokaInputDimension {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.DokaInputDimension label {
  position: absolute;
  right: 0.625em;
  font-size: 0.75em;
  text-transform: uppercase;
  pointer-events: none;
  color: var(--color-foreground-20);
  z-index: 1;
}
.DokaInputDimension button,
.DokaInputDimension input,
.DokaInputDimension label {
  font-size: 0.75em;
  font-weight: 400;
}
.DokaInputDimension button,
.DokaInputDimension input {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  box-sizing: border-box;
  font-variant-numeric: tabular-nums;
  padding: 0.625em 0 0.625em 0.625em;
  width: 100%;
  max-width: 7em;
  outline: transparent;
  border-radius: var(--border-radius);
  box-shadow: none;
  border: 1px solid var(--color-foreground-20);
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}
.DokaInputDimension button::-moz-selection,
.DokaInputDimension input::-moz-selection {
  background-color: var(--color-foreground-20);
}
.DokaInputDimension button::selection,
.DokaInputDimension input::selection {
  background-color: var(--color-foreground-20);
}
.DokaInputDimension button:hover,
.DokaInputDimension input:hover {
  border-color: var(--color-foreground-30);
}
.DokaInputDimension button:focus,
.DokaInputDimension input:focus {
  border-color: var(--color-focus-100);
}
.DokaInputDimension input {
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
  background-color: var(--color-foreground-5);
}
.DokaInputDimension input:-moz-placeholder-shown {
  border-style: dashed;
}
.DokaInputDimension input:-ms-input-placeholder {
  border-style: dashed;
}
.DokaInputDimension input:placeholder-shown {
  border-style: dashed;
}
.DokaInputDimension input::-moz-placeholder {
  color: var(--color-foreground-30);
}
.DokaInputDimension input:-ms-input-placeholder {
  color: var(--color-foreground-30);
}
.DokaInputDimension input::placeholder {
  color: var(--color-foreground-30);
}
.DokaInputDimension input[data-state="invalid"] {
  border-color: var(--color-error-75);
  box-shadow: inset 0 0 0.25em var(--color-error-25),
    0 0 0.25em var(--color-error-50);
}
.DokaInputDimension button {
  width: 10em;
  min-height: 2.65em;
}
.DokaInputDimension input::-webkit-inner-spin-button,
.DokaInputDimension input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.DokaInputDimension input::-ms-clear {
  display: none;
}
